.block {
  &:last-of-type(:not(.block-offers)) {
    margin-bottom: 0;
  }

  div.reading-text a {
    color: $colorAccent1;
  }
}

// --------------------------------------------------
// Blocks - Offer Briefs
// --------------------------------------------------
.block-offer-briefs {
  position: relative;
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }

  @media screen and (min-width: $smallScreen) {
    flex-direction: row;
    z-index: 2;
  }

  @media screen and (min-width: $mediumScreen) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.single-brief {
    div.block-offer-brief {
      @media screen and (max-width: $mediumScreen) {
        width: 100%;
        max-width: initial;
      }

      @media screen and (min-width: $smallScreen) and (max-width: $mediumScreen) {
        margin: 0 44px;
      }
    }
  }

  .block-offer-brief {
    width: 100%;
    margin-bottom: 70px;
    padding: 15px 40px 0;
    background: $colorAccent2;
    text-align: center;

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }

    &.no-icon {
      display: flex;
      flex-direction:  column;
      align-items:  center;
      justify-content: space-between;

      p.second-line {
        line-height: 1.2;
      }
    }

    &.no-anchor {
      p:last-of-type {
        margin-bottom: 30px;
      }
    }

    @media screen and (min-width: $smallScreen) {
      max-width: 625px;
      padding: 0 44px;
      margin-right: 12px;
      margin-bottom: 0;

      &.no-icon {
        padding: 36px 44px 0;
      }
    }

    @media screen and (min-width: $mediumScreen) {
      width: auto;
      padding: 0 60px;
      min-width: 425px;
      background: rgba($dark, 0.85);

      &.no-icon {
        padding: 40px 60px 0;
      }
    }

    img {
      margin-bottom: 10px;
      width: 70px;

      @media screen and (min-width: $smallScreen) {
        width: 85px;
        margin: -80px 0 20px;
      }

      @media screen and (min-width: $bigScreen) {
        width: initial;
      }
    }

    > p,
    > a {
      line-height: initial;
      margin: 0;
      color: $dark;

      @media screen and (min-width: $mediumScreen) {
        color: $light;
      }
    }

    .first-line,
    .third-line {
      @include font-size(24px);
    }

    .dynamic-txt {
      @include font-size(33px);
      @media screen and (max-width: 450px) {
        @include font-size(24px);
      }
    }

    .second-line {
      @include font-size(45px);

      .sub {
        font-size: 60%;
      }

      @media screen and (max-width: 450px) {
        @include font-size(28px);
      }
    }

    .no-third-line {
      margin-bottom: 26px;
    }

    .link {
      display: inline-block;
      margin: 14px 0 9px;
      @include font-size(14px);
    }

    .button {
      display: block;
      margin-bottom: -29px;
      color: $light;
    }
  }
}

.offers-passes {
  display: flex;
  justify-content: stretch;

  @media screen and (max-width: 1800px) {
    flex-wrap: wrap;
  }

  .pass-price {
    border: 2px solid $dark;
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    color: $dark;

    &:not(:first-child) {
      margin-left: -2px;

      @media screen and (max-width: 639px) {
        margin-left: 0;
      }

      @media screen and (max-width: 457px) {
        &:nth-child(2) {
          margin: -2px 0 0 0;
        }
      }
    }

    @media (min-width: 1025px) and (max-width: 1283px) {
      &:not(:first-child) {
        margin-left: 0px;
      }

      &:nth-child(2) {
        margin-left: -2px;
      }

      &:nth-child(3) {
        margin-top: -2px;
      }
    }

    @media screen and (max-width: 504px) {
      &:nth-child(2) {
        margin-left: 0;
        margin-top: -2px;
      }

      &:nth-child(3) {
        margin-top: -2px;
      }
    }

    .headline, .subline {
      display: flex;
      justify-content: space-between;
    }

    .headline {
      font-family: $secondaryFont;
      font-size: 16px;
    }

    .subline {
      font-family: $thirdFont;
      font-size: 12px;
      font-weight: 600;

      :last-child {
        color: $colorAccent1;
        text-decoration: line-through;
      }
    }

  }
}

// --------------------------------------------------
// Blocks - Image
// --------------------------------------------------

.block-image {
  text-align: center;

  &:first-of-type {
    margin-top: 20px;
  }

  img {
    @media screen and (max-width: $smallScreen - 1px) {
      max-width: 200px;
    }
  }
}

// --------------------------------------------------
// Blocks - Heading
// --------------------------------------------------

.block-heading {
  margin: 40px auto;
  padding: 0 20px;
  max-width: 900px;
  text-align: center;

  .title {
    margin: 0;

    @media (max-width: 899px) {
      margin-bottom: 35px;
    }
  }

  .text {
    font-family: $thirdFont;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    margin: 30px 0 0;
    color: $dark;

    @media (max-width: 899px) {
      font-size: 14px;
      line-height: 18px;
      margin: 20px 0 0;
    }
  }
}

// --------------------------------------------------
// Blocks - Activites
// --------------------------------------------------
.switcher-wrapper {
  display: flex;
  justify-content: center;

  @media (max-width: 899px) {
    background: white;
  }
}

.switcher {
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  background: #FFFFFF;
  border: 1px solid #C8C8C8;
  box-sizing: border-box;
  border-radius: 25px;

  @media (max-width: 899px) {
    border: none;
    border-radius: 0;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 3px;
    top: 3px;
    left: 3px;
    background: #2C444D;
    border-radius: 25px;
    transition: all .3s ease;

    @media (max-width: 899px) {
      bottom: 0;
      border-bottom: 2px solid $dark;
      border-radius: 0;
      background: transparent;
    }
  }

  &.day {
    &:before {
      width: calc(50% + 20px);
      transform: translateX(0);

      @media (max-width: 899px) {
        width: 50%;
        transform: translateX(3.5%);
      }
    }
  }

  &.night {
    &:before {
      width: 50%;
      transform: translateX(96.5%);

      @media (max-width: 899px) {
        transform: translateX(100%);
      }
    }
  }

  .switcher-btn {
    position: relative;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    color: #95A1A6;
    font-size: 16px;
    line-height: normal;
    text-decoration: none;
    z-index: 1;
    transition: color .3s ease;

    &:first-of-type {
      margin-right: 20px;
    }

    &:last-of-type {
      margin-left: 20px;
    }

    &.is-active {
      color: #fff;

      @media (max-width: 899px) {
        color: #95A1A6;
      }
    }
  }
}

.switcher-wrapper + .block-activites {
  margin: 40px 0 25px;
}

.block-activites-content-wrapper {
  position: relative;
  overflow-x: hidden;
}

.block-activites-wrapper {
  position: relative;
  overflow: hidden;
}

.block-activites {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 40px 0 25px;
  align-items: stretch;
  justify-content: center;
  opacity: 0;
  transition: all .75s ease;

  &.is-active {
    position: relative;
    opacity: 1;
  }

  &.mobile-swiper {
    .block-activite.small {
      @media(max-width: 899px) {
        width: 80%;
        margin-right: 20px;
      }
    }
  }

  @media screen and (min-width: $mediumScreen) {
    flex-direction: row;
  }

  @media screen and (min-width: $bigScreen) {
    margin: 40px 0 60px;
  }

    .block-activite {
      margin-bottom: 15px;
      cursor: pointer;

      &.small {
        margin-right: 13px;
        width: calc(25% - (39px / 4));
        &:last-child {
          margin-right: 0;
        }

        @media (max-width: 899px) {
          width: 100%;
          border: 1px solid $regularGrey;
        }
      }
      &.big {
        margin-right: 13px;
        width: calc(33.333% - (26px / 3));

        &:nth-child(3) {
          margin-right: 0;
        }

        @media (max-width: 899px) {
          width: 100%;
          border: 1px solid $regularGrey;
        }
      }

      @media screen and (min-width: 1024px) {
        img {
          max-height: none;
        }
      }

      .block-activite-content {
        padding: 16px 20px;
        transition: background-color ease-out .2s;

        @media (max-width: 1100px) {
          padding: 10px 16px;
        }

        .title {
          position: relative;
          width: 100%;
          font-family: $thirdFont;
          color: $dark;
          font-size: 14px;
          line-height: 22px;
          margin: 0;
          transition: color ease-out .2s;
          padding-right: 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 1100px) {
            font-size: 14px;
            line-height: 18px;
          }

          &:after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '+';
            font-family: $secondaryFontBold;
            font-size: 32px;
            line-height: 32px;
            color: $dark;
            transition: color ease-out .2s;

            @media (max-width: 1100px) {
              font-size: 24px;
              line-height: 24px;
            }
          }
        }
      }

      &:hover {
        .block-activite-content {
          background-color: $dark;
        }

        .title {
          color: white;

          &:after {
            color: white;
          }
        }
      }

      .block-activite-image {
        position: relative;

        img {
          width: 100%;
        }

        .text-on-picture {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          padding: 0 20px;
          font-family: $secondaryFontBold;
          font-size: 36px;
          line-height: 36px;
          color: white;
          text-align: center;
          text-shadow: 0 2px 4px rgba(#000, .76);
          pointer-events: none;
          cursor: pointer;
          text-transform: uppercase;
          max-width: 100%;

          @media (max-width: 1100px) {
            font-size: 30px;
            line-height: 30px;
          }

          @media (max-width: 1023px) {
            font-size: 26px;
            line-height: 26px;
          }

          @media (max-width: 899px) {
            width: 100%;
          }
        }
      }

      &.small .text-on-picture {
        font-size: 30px;
        line-height: 30px;

        @media (max-width: 1100px) {
          font-size: 26px;
          line-height: 26px;
        }

        @media (max-width: 1023px) {
          font-size: 18px;
          line-height: 18px;
        }

        @media (max-width: 899px) {
          font-size: 26px;
          line-height: 26px;
        }
      }
    }
}

// --------------------------------------------------
// Blocks - Services
// --------------------------------------------------

.block-services {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 0 25px;
  align-items: stretch;
  justify-content: center;
  overflow-x: hidden;

  @media screen and (min-width: $mediumScreen) {
    flex-direction: row;
  }

  @media screen and (min-width: $bigScreen) {
    margin: 20px 0 20px 0;
  }

  &.s-2018 {
    flex-wrap: wrap;
    flex-direction: row;

    @media (max-width: 899px) {
      flex-direction: column;
    }

    &.is-reverse {
      .block-heading {
        order: 2;
      }

      .block-services-smt-wrapper {
        order: 3;
      }
    }

    .block-services-smt-wrapper {
      width: 100%;
      display: flex;

      @media (max-width: 899px) {
        flex-direction: column;
      }
    }

    .services-slider {
      display: flex;
      width: 100%;

      &.mobile-swiper {
        .block-service-smt.small {
          @media (max-width: 899px) {
            width: 80%;
            margin-right: 20px;
          }
        }
      }
    }

    .block-service, .block-service-smt {
      margin-bottom: 15px;

      &.small {
        margin-right: 13px;
        width: calc(25% - (39px / 4));
        &:last-child {
          margin-right: 0;
        }

        @media (max-width: 899px) {
          width: 100%;
          border: 1px solid $regularGrey;
        }

        &.smt {
          width: calc(33.33334% - (26px / 3));

          @media (max-width: 899px) {
            width: 100%;
            border: 1px solid $regularGrey;
          }
        }
      }
      &.big {
        margin-right: 13px;
        width: calc(33.333% - (26px / 3));

        &:nth-child(3) {
          margin-right: 0;
        }

        @media (max-width: 899px) {
          width: 100%;
          border: 1px solid $regularGrey;
        }

        &.smt {
          width: calc(50% - (13px / 2));
          position: relative;
          margin-top: 60px;

          &:nth-child(2) {
            margin-right: 0;
          }

          @media (max-width: 1200px) {
            margin-top: 30px;
          }

          @media (max-width: 899px) {
            width: 100%;
            border: 1px solid $regularGrey;
          }

          .smt-logo {
            position: absolute;
            top: 0;
            left: 50%;
            width: 120px;
            z-index: 1;
            transform: translate(-50%,-50%);

            @media (max-width: 1200px) {
              width: 60px;
            }
          }
        }
      }

      @media screen and (min-width: 1024px) {
        img {
          max-height: none;
        }
      }

      .video {
        overflow: hidden;

        &:hover {
          .play-button {
            transform: scale(1.05);
          }
        }
      }

      .service-video {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: all .3s ease;

        &.is-active {
          opacity: 1;
        }
      }

      .play-button {
        position: absolute;
        right: 20px;
        bottom: 20px;
        width: 45px;
        height: 45px;
        opacity: 1;
        transition: all .3s ease;
      }

      .block-service-content {
        padding: 16px 20px;
        transition: background-color ease-out .2s;

        @media (max-width: 1100px) {
          padding: 10px 16px;
        }

        .title {
          position: relative;
          width: 100%;
          font-family: $thirdFont;
          color: $dark;
          font-size: 14px;
          line-height: 22px;
          margin: 0;
          transition: color ease-out .2s;
          padding-right: 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: 1100px) {
            font-size: 14px;
            line-height: 18px;
          }

          &:after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '+';
            font-family: $secondaryFontBold;
            font-size: 32px;
            line-height: 32px;
            color: $dark;
            transition: color ease-out .2s;

            @media (max-width: 1100px) {
              font-size: 24px;
              line-height: 24px;
            }
          }
        }
      }

      &:hover {
        .block-service-image .text-on-picture.sales {
          opacity: 1;
        }

        .block-service-content {
          background-color: $dark;
        }

        .title {
          color: white;

          &:after {
            color: white;
          }
        }
      }

      .block-service-image {
        position: relative;

        .text-on-picture {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          padding: 0 20px;
          font-family: $secondaryFontBold;
          font-size: 36px;
          line-height: 36px;
          color: white;
          text-align: center;
          text-shadow: 0 2px 4px rgba(#000, .76);
          pointer-events: none;
          cursor: pointer;
          text-transform: uppercase;
          transition: opacity .3s ease;
          opacity: 1;
          max-width: 100%;

          @media (max-width: 1100px) {
            font-size: 30px;
            line-height: 30px;
          }

          @media (max-width: 1023px) {
            font-size: 26px;
            line-height: 26px;
          }

          @media (max-width: 899px) {
            width: 100%;
          }

          &.is-hidden {
            opacity: 0;
          }

          &.sales {
            opacity: 0;
          }
        }
      }

      &.small .text-on-picture {
        font-size: 30px;
        line-height: 30px;

        @media (max-width: 1100px) {
          font-size: 26px;
          line-height: 26px;
        }

        @media (max-width: 1023px) {
          font-size: 18px;
          line-height: 18px;
        }

        @media (max-width: 899px) {
          font-size: 26px;
          line-height: 26px;
        }
      }

      .smt-table {
        margin: 20px 0 0 0;
        display: flex;
        align-items: stretch;
        background-color: rgba(#2A444E, .66);
        border: 1px solid white;

        @media (max-width: 899px) {
          margin-top: 10px;
        }

        &.desktop {
          display: flex;

          @media (max-width: 899px) {
            display: none;
          }
        }

        &.mobile {
          display: none;

          @media (max-width: 899px) {
            display: flex;
            margin: 0;
            padding: 15px;
            border-bottom: 1px solid lightgray;
            background: white;
          }
        }

        .smt-td {
          width: 180px;
          border-right: 1px solid rgba(white, .4);
          padding: 20px 0;

          @media (max-width: 899px) {
            padding: 0;
            width: calc(100% / 3);
            text-align: center;
          }

          &:last-child {
            border: none;
          }
        }

        .smt-td-value {
          font-size: 35px;
          line-height: 35px;
          margin: 0 0 5px;
          text-shadow: none;

          @media (max-width: 1023px) {
            font-size: 26px;
            line-height: 26px;
          }

          @media (max-width: 899px) {
            margin: 0;
            font-family: $secondaryFontBold;
            font-style: normal;
            font-size: 24px;
            line-height: 34px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: $dark;
          }
        }

        .smt-td-text {
          font-family: $thirdFont;
          font-size: 12px;
          line-height: 17px;
          font-weight: 600;
          text-shadow: none;
          margin: 0;

          @media (max-width: 1023px) {
            font-size: 8px;
            line-height: 10px;
          }

          @media (max-width: 899px) {
            margin: 0;
            font-family: $thirdFont;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: normal;
            text-align: center;
            color: $dark;
          }
        }
      }
    }

    .flickity-viewport {
      width: 100%;
    }

    .flickity-prev-next-button {
      &.previous {
        left: -25px;
      }
      &.next {
        right: -25px;
      }
    }
  }
}

.block-service, .block-service-smt {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  background: $light;

  @media screen and (min-width: $mediumScreen) {
    margin-right: 13px;
    width: calc(33.333% - (26px / 3));
  }

  &:last-of-type {
    margin-right: 0;
  }

  img {
    width: 100%;
    cursor: pointer;

    @media screen and (min-width: $mediumScreen) {
      max-height: 365px;
    }
  }
}

.block-service-content,
.block-activite-content {
  display: flex;
  flex-direction: column;
  padding: 20px 24px 18px;
  align-items: left;
  flex: 1;
  background: $light;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex-basis: auto;
  }

  @media screen and (min-width: $smallScreen) {
    padding: 35px 48px 33px;
  }

  .title {
    margin: 0 0 30px;
    @include font-size(26px);
    line-height: 30px;
    flex-grow: 1;

    @media screen and (min-width: $smallScreen) {
      @include font-size(30px);
      line-height: 34px;
    }

    @media screen and (min-width: $bigScreen) {
      @include font-size(32px);
      line-height: 35px;
    }
  }

  p {
    margin: 25px 0 30px;
  }

  .button {
    display: inline-block;
    margin-right: auto;
    padding-right: 40px;
    @include font-size(14px);

    @media screen and (min-width: $bigScreen) {
      @include font-size(18px);
      padding-right: 60px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      max-width: 240px;
    }

    &:after {
      right: 10px;
    }
  }
}

// --------------------------------------------------
// Blocks - Events
// --------------------------------------------------

.block-events {
  margin: 20px 0;

  &.featured {
    display: inline-block;
    width: 100%;

    @media screen and (max-width: 899px) {
      margin: 40px 0 20px;
      width: auto;
    }
  }

  .flickity-page-dots {
    display: none;
  }
}

.block-event {
  float: left;
  width: 100%;
  margin-right: 13px;

  @media screen and (min-width: $smallScreen) {
    width: calc(50% - (13px / 2));
  }

  @media screen and (min-width: $mediumScreen) {
    width: calc(33% - (26px / 3));
  }

  @media screen and (min-width: $bigScreen) {
    width: calc(25% - (39px / 4));
  }

  @media (max-width: 899px) {
    border: 1px solid $regularGrey;
  }

  &.featured {
    position: relative;
    width: 100%;

    .block-event-container {
      display: flex;
      align-items: stretch;

      @media (max-width: 899px) {
        flex-direction: column;
      }

      .block-event-thumbnail {
        position: relative;
        width: 100%;
        background-position: center center;

        .event-video {
          width: 100%;
          height: auto;
          transform: translate(-50%, -50%) scale(1.01);

          @media (max-width: 899px) {
            width: auto;
            height: 100%;
          }
        }

        .block-event-gradient {
          position: absolute;
          top: 50%;
          left: 0px;
          right: 0;
          bottom: 0px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
          opacity: 0.5;
        }
      }
    }

    .block-event-infos {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      color: white;
      background: $dark;
      transition: all ease-out .2s;
      cursor: pointer;
      z-index: 1;

      .event-infos-title {
        max-width: 425px;
        font-family: Stag;
        font-style: normal;
        font-weight: bold;
        font-size: 21px;
        line-height: 29px;
        text-align: center;
      }

      .event-infos-text {
        color: white;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;

        ul {
          list-style: none;
          padding: 0;

          li {
            position: relative;
            padding: 20px;

            &:after {
              content: '';
              position: absolute;
              left: 50%;
              bottom: 0;
              width: 100%;
              max-width: 115px;
              height: 2px;
              border-bottom: 1px solid rgba(255,255,255,.2);
              transform: translateX(-50%);
            }

            &:first-of-type {
              padding-top: 0;
            }

            &:last-of-type {
              padding-bottom: 0;

              &:after {
                content: none;
              }
            }
          }
        }
      }
    }

    &:hover {
      .block-event-infos {
        background: #233339;
      }
    }
  }

  .block-event-content {
    a {
      font-family: $thirdFont;

      span {
        font-family: $thirdFont;
      }
    }
  }

  .video {
    overflow: hidden;

    &:hover {
      .play-button {
        transform: translate(-50%, -50%) scale(1.05);
      }
    }
  }

  .event-video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease;

    &.is-active {
      opacity: 1;
    }
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45px;
    height: 45px;
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: all .3s ease;

    &.is-hidden {
      opacity: 0;
    }
  }

  .event-button {
    position: relative;
    display: block;
    padding: 16px 50px 14px 20px;
    margin: 0;
    background: $light;
    color: $dark;
    text-transform: none;
    font-family: $thirdFont;
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
    line-height: 22px;
    transition: all ease-out .2s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1100px) {
      font-size: 14px;
      line-height: 18px;
    }

    &:after {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      content: '+';
      font-family: $thirdFont;
      font-size: 32px;
      line-height: 32px;
      color: $dark;
      transition: color ease-out .2s;

      @media (max-width: 1100px) {
        font-size: 24px;
        line-height: 24px;
      }
    }
  }

  &:hover {
    .block-event-thumbnail {
      cursor: pointer;
    }

    .event-button {
      color: white;
      background-color: $dark;

      &:after {
        color: white;
      }
    }
  }
}

.block-event-thumbnail {
  position: relative;
  background-size: cover;
  background-position: bottom center;
  height: 70vw;

  @media screen and (min-width: $smallScreen) {
    height: 50vw;
  }

  @media screen and (min-width: $mediumScreen) {
    height: 30vw;
  }

  @media screen and (min-width: $bigScreen) {
    height: 25vw;
  }
}

.block-event-date {
    position: absolute;
    left: 20px;
    top: 20px;
    padding: 10px 15px;
    background: #2C444D;
    border: 1px solid #2C444D;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    text-align: center;
    color: #FFFFFF;
  background: $dark;
  text-align: center;
  z-index: 1;

  p {
    margin: 0;
    @include font-size(24px);
    line-height: 30px;

    span {
      display: block;
      @include font-size(36px);
    }
  }

  &.small {
    padding: 10px;
    p {
      font-family: $thirdFont;
      @include font-size(14px);
      line-height: 20px;
      font-weight: 600;
    }
  }
}

// --------------------------------------------------
// Blocks - Offers
// --------------------------------------------------

.block-offers {
  position: relative;
  display: flex;
  margin: 15vh 0 2vh;

  @media (min-width: $bigScreen + 201px) {
    margin: 6vh 0 10vh;
  }

  @media screen and (min-width: 1172px) and (max-width: $bigScreen + 200px) {
    margin: 6vh 0 6vh;
  }

  @media screen and (min-width: 1100px) and (max-width: 1171px) {
    margin: 6vh 0 2vh;
  }

  @media screen and (min-width: 1024px) and (max-width: 1099px) {
    margin: 5vh 0 2vh;
  }

  @media (max-width: 1023px) {
    margin: 40px 0 0;
  }

  &.container {
    @media (max-width: 899px) {
      padding: 0;
      margin-bottom: 0;
    }
  }

  .offer-left-img {
    display: block;
    margin: auto;
    width: 100%;
    z-index: 1;

    @media screen and (min-width: $mediumScreen) {
      margin: 0;
      width: 55%;
    }

    @media screen and (min-width: $bigScreen) {
      width: 52%;
    }
  }

  .block-offers-title {
    max-width: 500px;
  }

  &.s-2018 {
    &.image-left {
      .offer-left-img {
        @media screen and (min-width: 1024px) {
          margin-left: 44%;
        }
        @media screen and (min-width: 1440px) {
          margin-left: 48%;
        }
      }

      .block-offers-content-wrapper {
        @media screen and (min-width: 1024px) {
          left: 0;
          right: initial;
        }
      }
    }
  }
}

.block-offers-wrapper {
  position: relative;
  width: 100%;

  @media (max-width: 899px) {
    overflow: hidden;
    text-align: center;

    .link.modalites {
      display: inline-block;
      width: 100%;
      padding: 20px;
      margin: 40px auto;
      border: 1px solid $colorAccent1;
      font-family: Stag;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: normal;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $colorAccent1;
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
        color: white;
        background: $colorAccent1;
      }
    }
  }
}

.block-offers-content-wrapper {
  position: relative;
  z-index: 2;
  overflow-X: hidden;

  @media screen and (min-width: $mediumScreen) {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 55%;
  }

  & > div {
    background: $light;
  }

  .reading-text {
    @media (max-width: 899px) {
      ul {
        background: $grey;
        padding: 15px;
      }

      li {
        position: relative;
        padding: 15px 0;
        font-size: 12px;

        &:before {
          content: none;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 100%;
          max-width: 55px;
          height: 3px;
          border-bottom: 1px solid $regularGrey;
          transform: translateX(-50%);
        }

        &:last-of-type {
          &:after {
            content: none;
          }
        }
      }
    }

    a {
      color: $colorAccent1;
    }
  }

  .offer-warranty {
    display: flex;
    align-items: center;

    @media screen and (max-width: 650px) {
      flex-wrap: wrap;
    }

    .warranty-img {
      height: 75px;
      margin-right: 10px;

      @media screen and (max-width: 650px) {
        height: 40px;
      }
    }

    .warranty-text {
      max-width: 150px;
      font-family: $secondaryFontBold;
      color: $dark;
      text-align: left;
      font-size: 20px;
      line-height: 24px;
      margin: 0 20px 0 0;

      @media screen and (max-width: 650px) {
        margin: 0;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .warranty-bubble {
      position: relative;
      font-size: 16px;
      line-height: 22px;
      font-family: $thirdFont;
      color: $dark;
      padding: 20px;
      background-color: #E9ECED;
      max-width: 265px;
      font-weight: 700;
      margin: 0;

      @media screen and (max-width: 650px) {
        width: 100%;
        margin: 20px 0 0;
        max-width: none;
        padding: 15px 10px;
        font-size: 12px;
        line-height: 18px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 32px;
        left: -10px;
        transform: rotate(45deg);
        width: 22px;
        height: 22px;
        background-color: #E9ECED;

        @media screen and (max-width: 650px) {
          top: -6px;
          left: 24px;
        }
      }
    }
  }
}

.block-offers-labels {
  display: flex;

  .offer-label {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;

    display: flex;
    padding: 13px 27px 11px;
    background: $dark;

    cursor: pointer;

    p {
      // display: none;
      margin: 0;
      color: $light;
      @include font-size(20px);
      cursor: pointer;

      @media screen and (min-width: $smallScreen) {
        display: inline;
      }

      @media screen and (min-width: $bigScreen) {
        @include font-size(24px);
      }
    }

    .svg-icon {
      margin-left: 15px;

      @media screen and (min-width: $smallScreen) {
        margin-left: 0;
        margin-right: 20px;
      }

      path,
      polygon,
      ellipse {
        fill: $light;
      }
    }

    &:after {
      position: absolute;
      top: 50%;
      right: 20px;

      display: block;
      content: '';
      width: 15px;
      height: 9px;

      background: url('/assets/images/right-arrow-picto.svg') no-repeat center;
      transform: translateY(-50%);
    }

    &.is-active {
      background: $light;

      p {
        color: $dark;
      }

      .svg-icon path,
      .svg-icon polygon,
      .svg-icon ellipse {
        fill: $dark;
      }

      &:before {
        position: absolute;
        top: -12px;
        left: 0;
        display: block;
        content: '';
        width: 100%;
        height: 13px;
        background: $light;

        @media screen and (min-width: $bigScreen) {
          top: -17px;
          height: 18px;
        }
      }

      &:after {
        background: url('/assets/images/down-arrow-picto.svg') no-repeat center;
      }
    }

    .label.smt {
      font-size: 22px;
      line-height: 22px;

      span {
        display: block;
        font-family: $thirdFont;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
      }
    }
  }

  @media screen and (max-width: 820px) {
    flex-direction: column;

    .offer-label {
      flex-basis: auto;
      padding: 15px 27px;
    }
  }
}

.block-offers-content {
  padding: 20px 30px;
  display: none;

  &.is-active {
    display: block;
  }

  @media screen and (min-width: $smallScreen) {
    padding: 30px 40px;
  }

  @media screen and (min-width: $bigScreen) {
    padding: 30px 105px 30px 55px;
  }

  @media (max-width: 899px) {
    text-align: center;
  }

  .block-offers-title {
    p, h1, h2, h3 {
      @extend .title2;
      margin: 5px 0;

      @media screen and (max-width: $bigScreen - 1px) {
        margin-top: 10px;
        max-width: initial;
        @include font-size(24px);
      }
    }
  }

  div.reading-text {
    p {

      &:first-of-type {
        @include font-size(14px);

        @media screen and (min-width: $bigScreen) {
          @include font-size(20px);
        }
      }
    }
  }

  .button-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: left;

    @media screen and (min-width: $smallScreen) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    @media screen and (min-width: $bigScreen) {
      margin-top: 30px;
    }

    img {
      margin-bottom: 30px;

      @media screen and (max-width: $bigScreen - 1px) {
        max-width: 150px;
      }

      @media screen and (max-width: $smallScreen) {
        margin-top: 20px;
      }
    }
  }

  .offer-warranty-wrapper {
    position: relative;
  }

  .bubble-info-wrapper {
    position: absolute;
    top: 0;
    right: -300px;
    width: 275px;

    @media (max-width: 899px) {
      position: relative;
      top: unset;
      right: unset;
      margin: 0 auto;
    }

    .bubble-info {
      position: relative;
      background-color: #e9eced;
      color: $dark;
      font-family: $thirdFont;
      font-weight: 400;
      font-size: 16px;
      padding: 15px 25px;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -12px;
        border-width: 12px;
        border-style: solid;
        border-color: transparent #e9eced transparent transparent;

        @media (max-width: 899px) {
          top: -18%;
          right: 40%;
          border-color: transparent transparent #e9eced transparent;
          transform: translateX(-50%);
        }
      }
    }
  }

  .link {
    display: inline-block;
    margin-top: 20px;

    @include font-size(16px);

    @media screen and (min-width: $smallScreen) {
      margin-top: 10px;
    }
  }

}

// --------------------------------------------------
// Blocks - Accordion
// --------------------------------------------------

.block-accordion {
  margin: 40px auto;

  @media screen and (min-width: $bigScreen) {
    margin: 80px auto;
  }
}

.accordion-wrap {
  width: 100%;
  max-width: 1200px;
  background-color: #e9eced;
  margin: auto;

  .title2 {
    color: #2b444d;
    font-size: 26px;
    width: 100%;
    margin: 0;
  }

  .accordion-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    background-color: #fff;
    padding: 15px 20px;
    margin-bottom: 6px;

    transition: all 0.2s;

    @extend .clearfix;
    &:hover {
      background-color: #2b444d;
      h2, .plus, .moins {
        color: #fff;
      }
    }

    h2 {
      color: #2b444d;
      font-size: 22px;
      display: inline-block;
      float: left;
    }
    .plus, .moins {
      color: #2b444d;
      font-size: 22px;
      display: inline-block;
      float: right;
    }
    .moins {
      display: none;
    }

    &.active {
      background-color: #2b444d;
      h2, .plus, .moins {
        color: #fff;
      }
      .plus {
        display: none;
      }
      .moins {
        display: block;
      }
    }
  }

  .accordion-content {
    display: none;
    background-color: #fff;
    padding: 25px;
    margin-bottom: 6px;

    h3 {
      @include font-size(18px);
      font-weight: 600;
      @extend .reading-text;
    }

    ul {
      margin: 0;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.5;
      li {
        text-align: left;
        margin-top: 0;
        margin-bottom: 10px;
        @extend .reading-text;
      }
    }

    p {
      @extend .reading-text;
    }
  }
}

// --------------------------------------------------
// Blocks - Trio Boxes
// --------------------------------------------------

.block-trio-boxes {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &.two-elements {
    min-height: 30vw;

    .flex-wrapper {
      display: flex;
      align-items: center;
      padding: 25px 32px;

      @media screen and (min-width: $mediumScreen) {
        padding: 50px 64px;
      }
    }

    @media screen and (min-width: $mediumScreen) {
      > div {
        width: 50%;
      }
    }

    .left-box {
      @media screen and (min-width: $mediumScreen) and (max-width: $bigScreen - 1px) {
        width: 100%;
      }
    }

    .middle-box {
      background-position: center bottom;

      p {
        text-shadow: 1px 2px 3px rgba(0, 1, 1, .6);
      }
    }
  }

  .flex-wrapper {
    background: white;
  }

  @media screen and (min-width: $mediumScreen) {
    flex-direction: row;
  }

  > div {
    width: 100%;

    @media screen and (min-width: $bigScreen) {
      flex-grow: 1;
      flex-basis: 0;
      width: calc(100% / 3);
    }
  }

  .left-box,
  .right-box {
    background: $light;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    p {

      &:first-of-type {
        @include font-size(18px);

        @media screen and (min-width: $bigScreen) {
          @include font-size(20px);
        }
      }
    }

    .button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: left;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        flex: none;

        img {
          display: block;
        }
      }
    }

    .button {
      margin-top: 20px;

    }

  }

  .reading-text {
    flex-grow: 1;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      flex-basis: auto;
    }
  }

  .right-box,
  .flex-wrapper {
    padding: 25px 32px;

    @media screen and (min-width: $mediumScreen) and (max-width: $bigScreen - 1px) {
      width: 32%;
    }

    @media screen and (min-width: $bigScreen) {
      padding: 50px 64px;
    }
  }

  .middle-box {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (max-width: $mediumScreen - 1px) {
      min-height: 50vw;
    }

    @media screen and (min-width: $mediumScreen) and (max-width: $bigScreen - 1px) {
      width: 36%;
    }

    .title2 {
      margin: 0;
      padding: 24px 18px;
      font-size: 18px;
    }
  }
}

// --------------------------------------------------
// Blocks - Promo
// --------------------------------------------------

.block-promo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 90px 0 0;

  img {
    margin: 0 0 20px;
    max-width: 100px;
  }

  .promo-wrapper {
    width: 100%;
    padding: 30px;
    background: $dark;

    @media screen and (min-width: $smallScreen) {
      padding: 30px 0 40px;
    }

    .title2 {
      margin-bottom: 20px;
    }

    .reading-text {
      margin: 0 auto;
      max-width: 800px;
    }
  }
}

// --------------------------------------------------
// Blocks - Experience
// --------------------------------------------------

.block-experience {
  display: flex;

  margin: 0 0 70px 0;

  .left {
    position: relative;

    flex: 2;

    img {
      width: 100%;
    }

    .overlay {
      position: absolute;

      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: rgba(44,68,77,.45);

      .hero-form-title {
        max-width: 600px;
      }

      a {
        width: 98px;
        height: 98px;

        @media screen and (max-width: 768px) {
          width: 58px;
          height: 58px;
        }

        background: url('/assets/images/play.png') no-repeat center center / contain;
      }
    }
  }

  .right {
    flex: 1;

    padding: 125px 43px 125px 63px;

    background: #fff;

    .title {
      margin: 0;
    }

    .reading-text {
      margin: 35px auto;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    .left {
      height: 42vw;
      min-height: 280px;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0 0 70px;

    .right {
      padding: 25px;
    }
  }

}

// --------------------------------------------------
// Blocks - FlyTo
// --------------------------------------------------
.block-flyto {
  display: flex;

  &.column {
    flex-direction: column;
    margin-bottom: -60px;
    margin-top: 40px;

    @media screen and (max-width: 860px) {
      margin-bottom: 60px;
    }
  }

  .left-panel, .right-panel, .panel {
    flex: 1 1 100%;

    &:first-child {
      padding: 50px 77px;

      @media screen and (max-width: 860px) {
        padding: 20px;
      }

      // FlyTo Labels
      .block-flyto-labels {
        position: relative;
        top: -72.5px;
        display: flex;

        .flyto-label {
          position: relative;
          flex-basis: 0;
          flex-grow: 1;

          display: flex;
          padding: 13px 10px 11px;
          justify-content: center;
          align-items: center;
          background: $dark;

          cursor: pointer;

          @media (min-width: 1025px) and (max-width: 1180px) {
            flex-basis: auto;
          }

          p {
            margin: 0;
            color: $light;
            @include font-size(20px);
            cursor: pointer;

            @media screen and (min-width: $smallScreen) {
              display: inline;
            }

            @media screen and (min-width: $bigScreen) {
              @include font-size(20px);
            }

            @media screen and (max-width: 1180px) {
              font-size: 14px;
              text-align: center;
              line-height: 1;
            }
          }

          &.is-active {
            background: $light;

            p {
              color: $dark;
            }

          }

        }
      }

      // FlyTo Label Content
      .block-flyto-content-wrapper {
        position: relative;
        background: $light;
        z-index: 2;

        @media screen and (min-width: $mediumScreen) {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 55%;
        }

        .reading-text {
          a {
            color: $colorAccent1;
          }
        }
      }

      .block-flyto-content {
        padding: 20px 30px;
        display: none;

        @media (max-width: 860px) {
          margin-top: 50px;
          margin-bottom: -60px;
        }

        &.is-active {
          display: flex;
          flex-direction: column;

          img {
            margin: -55px auto 30px;
            width: 100%;
            max-width: 100px;
          }
        }

        @media screen and (min-width: $smallScreen) {
          padding: 30px 40px;
        }

        @media screen and (min-width: $bigScreen) {
          padding: 30px 105px 30px 55px;
        }

        .block-flyto-title {
          p, h1, h2, h3 {
            @extend .title2;
            margin: 5px 0;

            @media screen and (max-width: $bigScreen - 1px) {
              margin-top: 10px;
              max-width: initial;
              @include font-size(24px);
            }
          }
        }

        .main-text {
          p {
            @extend .reading-text;
            color: $light;
            font-weight: 600;
            letter-spacing: 0;
            max-width: 800px;
            margin: 0 auto;

            &:first-of-type {
              @include font-size(16px);

              @media screen and (min-width: $bigScreen) {
                @include font-size(20px);
              }
            }
          }
        }

        .link {
          color: #fff;
          text-align: center;
          padding-top: 10px;
          text-decoration: underline;
        }
      }

    }

    &:last-child {
      background: $light;
      padding: 76px 32px 56px 67px;

      .flyto-logo {
        margin-bottom: 26px;
      }
    }
  }

  .panel {
    padding-bottom: 150px !important;

    .block-flyto-labels {
      &.large {
        position: relative;
        top: -75px;

        width: fit-content;
        justify-content: center;
        margin: 0 auto;

        .flyto-label {
          flex: 1 0 70%;
          width: fit-content;
        }

        @media screen and (max-width: 1024px) {
          width: auto;
          justify-content: normal;

          .flyto-label {
            flex: 1;
            width: auto;
          }
        }

        @media screen and (max-width: 860px) {
          top: -45px;
        }
      }
    }
  }

  .block-flyto-companies {
    @media (min-width: 1024px) {
      width: fit-content;
    }

    position: relative;
    top: -100px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 50px 10px;
    margin: 0 auto;
    width: fit-content;

    background: $light;

    .reading-text {
      text-align: center;
      max-width: 800px;
      @media (max-width: 860px) {
        text-align: left;
      }
    }

    .block-flyto-companies-footer {
      display: flex;

      padding: 15px 30px;

      .block-flyto-company {
        display: flex;
        flex-direction: column;
        align-items: center;

        flex: 1;

        &:first-child {
          padding: 30px 40px 30px 0;
          border-right: 1px solid $grey;
        }

        &:last-child {
          padding: 30px 0 30px 40px;
        }

        .flyto-logo {
          margin-bottom: 26px;
          height: 68px;
        }
      }

    }

    @media screen and (max-width: 425px) {
      .title2 {
        font-size: 20px;
      }
    }

    @media screen and (max-width: 860px) {
      top: 0;
      align-items: start;
      width: 100%;

      padding: 40px 20px 10px;

      .reading-text {
        margin: 20px 0;
      }

      .block-flyto-companies-footer {
        flex-direction: column;
        padding: 0;

        .block-flyto-company {
          align-items: start;

          flex: 1;

          &:first-child {
            padding: 20px 0;
            border-right: none;
          }

          &:last-child {
            padding: 20px 0;
          }

          .flyto-logo {
            margin-bottom: 20px;
          }
        }

      }
    }

  }

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

// --------------------------------------------------
// Blocks - Prices Table
// --------------------------------------------------
.block-prices-table {
  display: flex;

  margin: 40px auto;
  max-width: 625px;

  .price-table-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 0;
    flex-grow: 1;

    padding: 20px;

    border: 2px solid $dark;

    font-family: $secondaryFont;
    color: $dark;
    font-size: 16px;
    letter-spacing: 0.0445em;

    &:last-child {
      margin-left: -2px;
    }
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;

    .price-table-cell {
      &:last-child {
        margin-left: 0;
        margin-top: -2px;
      }
    }
  }
}

// --------------------------------------------------
// Blocks - Privileges
// --------------------------------------------------
.block-privileges {
  margin: 40px 0 80px;

  @media screen and (min-width: $bigScreen) {
    margin: 80px 0;
  }

  .flickity-page-dots {
    display: none;
  }

  .button-footer {
    margin: 70px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link {
    display: block;
    text-align: center;
  }
}

.block-privilege {
  float: left;
  width: 100%;
  margin-right: 13px;

  @media screen and (min-width: $smallScreen) {
    width: calc(50% - (13px / 2));
  }

  @media screen and (min-width: $mediumScreen) {
    width: calc(33% - (13px / 3));
  }

  &:hover {
    .block-privilege-thumbnail {
      cursor: pointer;
    }

    .privilege-button {
      font-family: $thirdFont;
      color: white;
      background-color: $dark;

      &:after {
        color: white;
      }
    }
  }

  .block-privilege-content {
    a {
      font-family: $thirdFont;

      span {
        font-family: $thirdFont;
      }
    }
  }

  .privilege-button {
    position: relative;
    display: block;
    padding: 16px 50px 14px 20px;
    margin: 0;
    background: $light;
    color: $dark;
    text-transform: none;
    font-family: $thirdFont;
    font-weight: 600;
    text-decoration: none;
    font-size: 14px;
    line-height: 22px;
    transition: color ease-out .2s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1100px) {
      font-size: 14px;
      line-height: 18px;
    }

    &:after {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      content: '+';
      font-family: $secondaryFontBold;
      font-size: 32px;
      line-height: 32px;
      color: $dark;
      transition: color ease-out .2s;

      @media (max-width: 1100px) {
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
}

.block-privilege-thumbnail {
  position: relative;
  background-size: cover;
  background-position: bottom center;
  height: 20vw;
}

@media (max-width: 1023px) {
  .block-privilege-thumbnail {
    height: 30vw;
  }
}

@media (max-width: 768px) {
  .block-privilege-thumbnail {
    height: 45vw;
  }
}

.block-privilege, .block-event {
  &:nth-child(4) {
    margin-left: 5px;
  }
}

// [data-aos="zoom-out"] {
//   opacity: 0;
//   transition-property: transform, opacity;

//   &.aos-animate {
//     opacity: 1;
//   }
// }
