// --------------------------------------------------
// Header
// --------------------------------------------------

.header-container {
  @media screen and (max-width: 899px) {
    position: absolute;
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }
}

.header {
  position: relative;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  z-index: 101;
  box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.2);
  transition: all 0.3s cubic-bezier(0, 0.000, 0.175, 1.000);

  @media screen and (min-width: 900px) {
    padding: 8px 15px;
  }

  @media screen and (max-width: 899px) {
    background: transparent;
    box-shadow: none;
  }

  &:not(.terms){
    @media screen and (min-width: $mediumScreen) {
      position: fixed;
      padding: 10px 68px 40px;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .header-right,
  .header-right p {
    display: flex;
    align-items: center;
  }

  &.has-region {
    // @media screen and (min-width: $mediumScreen) {
    //   margin-top: 45px;
    // }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-top: 0;
    }
  }

  &.has-promo {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-top: 0;
    }
  }

  &.double-header {
    @media screen and (min-width: $mediumScreen) {
      margin-top: 55px;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-top: 0;
    }
  }

  &.is-sticky,
  &.terms {
    position: relative;
    margin-top: 0;
    box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.2);
    background-color: #fff;

    @media screen and (min-width: $mediumScreen) {
      padding: 15px 50px;
    }

    &:not(.terms) {
      @media screen and (min-width: $mediumScreen) {
        position: fixed;
        top: 0;

        z-index: 90;
      }
    }

    .tagline {
      display: none;
    }

    p.light,
    p.light a {
      color: $dark;
    }

    .header-left .logo {
      width: 145px;

      @media (max-width: 899px) {
        display: none;
      }
    }

    .header-right p,
    .header-right a {
      text-shadow: none;
    }
  }
}

.header-nav {
  display: flex;
  flex-direction: row;

  @media (max-width: 899px) {
    justify-content: space-between;
    align-items: center;

  }
}

.header-left {
  @media (min-width: 900px) {
  img {
    display: block;
    margin: auto;
  }

  .logo,
  .logo[src$=".svg"]{
    width: 108px;

    @media screen and (min-width: $smallScreen) {
      width: 150px;

      &.is-alternate {
        width: 317px;

        position: relative;
        top: 0;
      }
    }

    @media screen and (min-width: $bigScreen) {
      width: 175px;

      &.is-alternate {
        width: 317px;

        position: relative;
        top: 0;
      }
    }
  }

  .tagline {
    margin-top: 5px;

    @media screen and (max-width: $mediumScreen - 1px) {
      display: none;
    }
  }
  }

  .lang {
    display: none;
  }

  @media (max-width: 899px) {
    width: 100%;
    max-width: 45px;

    .lang {
      display: block;
    }

    img {
      display: none;
    }
  }
}

.header-middle {
  display: none;

  @media (max-width: 899px) {
    display: block;
    margin: 0 auto;

    img {
      display: block;
      margin: auto;
    }

    .logo,
    .logo[src$=".svg"]{
      width: 108px;

      &.is-alternate {
        width: 175px;
      }

      @media screen and (min-width: $smallScreen) {
        width: 150px;

        &.is-alternate {
          width: 175px;
        }
      }

      @media screen and (min-width: $bigScreen) {
        width: 175px;

        &.is-alternate {
          width: 175px;
        }
      }
    }

    .tagline {
      margin-top: 5px;

      @media screen and (max-width: $mediumScreen - 1px) {
        display: none;
      }
    }
  }
}

.header-right {
  margin: auto 0 auto auto;

  @media screen and (max-width: 899px) {
    margin: 0;
    width: 100%;
    max-width: 45px;

    .v-separator,
    .lang {
      display: none;
    }
  }

  p,
  a {
    display: inline-block;
    margin: 0 5px;
    @include font-size(18px);

    @media screen and (min-width: $bigScreen) {
      margin: 0 10px;
    }

    @media screen and (max-width: 321px) {
      @include font-size(16px);
    }
  }

  p.light > a,
  p.light {
    @media screen and (max-width: $mediumScreen - 1px) {
      color: $dark;
    }

    @media screen and (max-width: 899px) {
      color: white;
    }
  }

  .phone {
    display: block;

    @media (max-width: 899px) {
      display: none;
      font-size: 22px;
    }

    &.mobile {
      display: none;

      @media (max-width: 899px) {
        display: block;
      }
    }
  }

  .button {
    margin-left: 10px;
    padding-right: 50px;
    display: none;

    @media screen and (min-width: $smallScreen) {
      display: inline-block;
    }

    @media screen and (min-width: $bigScreen) {
      margin-left: 20px;
    }
  }
}
