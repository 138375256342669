.hero {
  position: relative;

  .flickity-page-dots {
    display: none;
  }

  &.always-on {
    .form-booking {
      :nth-child(n) {
        flex: 1;
      }

      #f-ticket-types, #f-months {
        text-transform: uppercase;
      }
    }

    @media screen and (max-width: 767px) {
      .form-booking {
        flex-direction: column;

        :nth-child(n) {
          width: 100%;
        }
      }
    }
  }

  &.hero-avt {
    @media (max-width: 899px) {
      height: calc(85vh - 149px);
      margin-bottom: 220px;
    }

    @media (min-width: 1430px) and (max-width: 1550px) {
      .hero-logo {
        bottom: 50px;
        width: 80px;
        height: 80px;
      }
    }

    @media (max-width: 1429px) {
      .hero-logo {
        display: none;
      }
    }
  }

  &.hero-smt {
    margin-bottom: 70px;

    @media (max-width: 899px) {
      .js-gallery {
        height: 50vh !important;
      }
    }

    @media (max-width: 450px) {
      .js-gallery {
        height: 70vh !important;
      }
    }

  }

}

.hero-wrapper {
  position: relative;

  @media screen and (max-width: 1024px) {
    background: #fff;
  }

  .hero-gradient {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 300px;
    width: 100%;
    content: '';
    background: -moz-linear-gradient(top, rgba(42, 68, 78, 0) 0%, rgba(0, 0, 0, 0.5) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(42, 68, 78, 0) 0%, rgba(0, 0, 0, 0.5) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(42, 68, 78, 0) 0%, rgba(0, 0, 0, 0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#002a444e', endColorstr='#80000000', GradientType=0); /* IE6-9 */
    @media screen and (max-width: 1024px) {
      display: none;
    }
    @media (max-width: 767px) {
      height: 200px;
      bottom: 0;
    }
  }

  .hero-image {
    width: 100%;
    height: 50vh;
    min-height: 0;
    background-size: cover;
    background-position: center center;

    @media screen and (min-width: $mediumScreen) {
      height: 80vh;
      min-height: 650px;
    }
  }

  .best-price-tag {
    position: absolute;
    top: 36vh;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    width: 100%;
    max-width: 425px;
    height: auto;

    @media (min-width: 768px) and (max-width: 1023px) {
      top: 10vh;
      padding: 0 25px;
    }

    @media screen and (max-width: 767px) {
      top: 20vh;
      padding: 0 25px;
      max-width: 325px;
    }
  }

  .hero-video {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      display: block;
      content: '';
      width: 60px;
      height: 60px;
      background: url('../images/play.png') no-repeat center;
      background-size: contain;

      @media screen and (min-width: $mediumScreen) {
        width: 80px;
        height: 80px;
        transform: translateY(-120px);
      }
    }
  }

  .js-gallery {
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 899px) {
      height: 100%;

      .hero-image {
        height: 100%;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    &.tonik {
      @media screen and (max-width: $smallScreen) {
        height: 135vw;
      }

      @media screen and (min-width: $smallScreen + 1) and (max-width: $mediumScreen) {
        height: 90vw;
      }

      @media screen and (min-width: $mediumScreen + 1) {
        height: auto;
      }
    }
  }

  .flickity-prev-next-button {
    z-index: 10;
  }
}

.hero-logo {
  position: absolute;
  bottom: 23px;
  right: 23px;
  z-index: 1;

  @media screen and (max-width: $smallScreen - 1px) {
    width: 80px;
  }

}

  .hero-form-section {
  width: 100%;
  max-width: 1240px;
  padding: 0 20px;
  position: absolute;
  bottom: -30px;
  left: 0;
    right: 0;
    margin: auto;
  z-index: 1;

  @media (max-width: 899px) {
    display: flex;
    flex-direction: column;
    bottom: 15px;
    padding: 0;
  }

  .hero-form-title {
    text-transform: none;
  }

    .tooltip-wrapper {
      @media (max-width: 899px) {
        padding: 0 15px;
      }
    }

  .form-booking {
    display: flex;
    opacity: 1;

    @media (max-width: 767px) {
      display: none;

      .hero-form-details {
        display: none;
      }
    }

    .input-wrapper {
      width: 220px;

      @media (max-width: 767px) {
        width: 50%;
      }

      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        border-right: 1px solid #5C6A6D;
      }

      @media (max-width: 767px) {
        &:nth-child(1), &:nth-child(2) {
          border-bottom: 1px solid #5C6A6D;
        }
      }

      &.select-wrapper span {
        @media (max-width: 1023px) {
          padding-left: 25px;
        }
      }

      input, select {
        height: 65px;
        border-radius: 0;
        border: none;
        margin-bottom: 0;
        cursor: pointer;
        outline: none;

        @media (max-width: 767px) {
          height: 60px;
        }

        &.js-datepicker {
          background-image: url('/assets/images/icon-calendar.svg');

          @media (max-width: 1023px) {
            background-position: right 10px center;
          }
        }
      }

      select {
        background-image: url('/assets/images/icon-chevron-down.svg');
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;

        &::-ms-expand {
          display: none;
        }

        @media (max-width: 1023px) {
          background-position: right 10px center;
          padding-left: 10px;
        }
      }

      label span {
        top: 25px;
        text-transform: uppercase;
        font-weight: 600;
        cursor: pointer;
        pointer-events: none;

        @media (max-width: 1023px) {
          padding: 0 10px;
        }

        @media (max-width: 767px) {
          top: 24px;
        }
      }

      label.is-active span {
        -webkit-transform: scale(0.68);
        -ms-transform: scale(0.68);
        transform: scale(0.68);
        top: 8px;
        padding-left: 0;
      }

      .picker__holder {
        width: 200%;
      }
    }

    .button {
      display: flex;
      align-items: center;
      flex: 1 0 auto;
      text-align: left;
      cursor: pointer;

      @media (min-width: 525px) {
        line-height: 1;
      }

      @media (max-width: 767px) {
        padding: 21px 37px 21px 20px;
      }

      &:hover {
        transform: scale(1);
      }
    }
  }
}

.hero-form-title {
  font-family: $secondaryFontBold;
  color: white;
  font-size: 45px;
  line-height: 45px;
  margin: 15px 0;
  text-align: center;

  @media (max-width: 899px) {
    order: 0;
    font-size: 24px;
    line-height: 1.2;
    margin: 0;
    padding: 0 20px;
  }
}

.hero-form-details {
  display: block;
  margin-bottom: 25px;
  text-align: center;
  font-family: $thirdFont;
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-weight: 700;
  text-decoration: none;

  @media (max-width: 899px) {
    display: none;
  }

  &.mobile {
    display: none;

    @media (max-width: 899px) {
      display: block;
      margin: -270px 0 0 0;
      background: white;
      padding: 70px 20px 30px;

      a {
        display: inline-block;
        width: 100%;
        padding: 20px;
        border: 1px solid $colorAccent1;
        font-family: Stag;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: normal;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-decoration: none;
        color: $colorAccent1;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
          background: $colorAccent1;
          color: white;
        }
      }
    }
  }
}

////////////////////////
// Hero Golf Section //
///////////////////////

.hero-golf-section {
  width: 100%;
  max-width: 620px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1024px) {
    position: relative !important;
    max-width: none !important;
  }

  @media (max-width: 767px) {
    position: relative;
    max-width: 100%;
  }

  .tooltip-wrapper {
    @media (max-width: 899px) {
      padding: 0 15px;
    }

    @media (max-width: 767px) {
      position: absolute;
      bottom: 205px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
  }

  &.with-3-icons {
    max-width: 710px;

    .hero-golf-main {
      padding-top: 30px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 45px;

      @media (max-width: 899px) {
        padding: 40px;
      }

      @media (max-width: 767px) {
        padding: 10px;
      }
    }
  }
}

.hero-golf-tabs {
  display: flex;
  justify-content: center;
  align-items: stretch;

  .hero-golf-tab {
    min-width: 170px;
    padding: 10px 25px;
    background-color: white;
    opacity: .57;
    transition: opacity ease-out .2s;
    text-align: center;
    margin: 0 1px;
    cursor: pointer;

    @media (max-width: 767px) {
      min-width: 140px;
      padding: 10px 18px;
      margin: 0 2.5px;
    }

    &.is-active {
      opacity: 1;
    }
  }

  .hero-golf-tab-title {
    font-family: $secondaryFontBold;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0;
    color: $dark;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 16px;
    }
  }

  .hero-golf-tab-subtitle {
    font-family: $thirdFont;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0;
    color: $dark;
    margin: 0;
  }
}

.hero-golf-main {
  padding: 70px 70px 80px;
  background-color: rgba($dark, .85);
  color: white;
  font-family: $secondaryFontBold;
  letter-spacing: 0;
  display: none;

  @media (max-width: 1024px) {
    background-color: rgba($dark, 1);
  }

  &.is-active {
    display: block;
  }

  &.one-offer {
    padding: 50px 70px 80px;

    @media (max-width: 767px) {
      padding: 10px;
    }
  }

  .hero-golf-main-subtitle {
    margin: 0 0 10px;
    text-align: center;
    font-size: 32px;

    @media (max-width: 767px) {
      font-size: 26px;
    }
  }

  .hero-golf-price-wrapper {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }
  }

  .price-section {
    display: flex;
    margin: 0 23px 0 0;

    @media (max-width: 767px) {
      margin: 0 0 15px 0;
    }

    &.golf {
      align-items: center;
      width: calc(50% - 23px);

      @media (max-width: 767px) {
        justify-content: center;
        width: 100%;
        margin: 0;
        text-align: center;
      }

      p {
        font-size: 18px;
        line-height: 18px;
      }

      del {
        font-size: 16px;
      }
    }

    &.center {
      justify-content: center;
      margin: 0;

      @media (max-width: 767px) {
        margin: 10px 0 10px 0;
      }

      .big-price {
        @media (max-width: 767px) {
          font-size: 80px;
          line-height: 55px;
        }
      }
    }

    .hero-golf-main-subtitle {
      margin-bottom: 0;
      text-align: left;
      font-size: 24px;
    }

    .big-price {
      display: flex;
      font-size: 120px;
      line-height: 100px;
      margin: 0 5px 0 0;

      @media (max-width: 767px) {
        font-size: 100px;
        line-height: 85px;
        margin: 0 10px 0 0;
      }

      span {
        font-size: 33px;
        line-height: 33px;
        margin: 0 2px 0 0;
      }
    }

    .price-right-section {
      .cent-price {
        font-size: 33px;
        line-height: 33px;
        margin: 0 0 6px;

        @media (max-width: 767px) {
          margin: 0 0 1px;
        }
      }

      .label {
        font-size: 20px;
        line-height: 20px;
        margin: 0 0 14px;

        @media (max-width: 767px) {
          margin: 0 0 5px;
        }
      }

      .regul {
        font-size: 17px;
        line-height: 17px;
        margin: 0;
        text-decoration: line-through;
      }
    }
  }

  .options-section {
    max-width: 210px;

    @media (max-width: 767px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: unset;
    }

    .v-separator {
      display: none;

      @media (max-width: 767px) {
        display: block;
        width: 1px;
        height: 75px;
        background: rgba(#fff, .5);
      }
    }

    .option {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        text-align: center;
        flex-direction: column;
        width: calc(50% - 20px);
      }

      p {
        font-family: $secondaryFontBold;
        letter-spacing: 0;
        font-size: 18px;
        line-height: 18px;
        margin: 0;

        @media (max-width: 767px) {
          font-family: $thirdFont;
          font-size: 14px;
          font-weight: 600;
        }
      }

      &.cart {
        margin-bottom: 15px;

        @media (max-width: 767px) {
          margin-bottom: 0;
        }

        img {
          height: 36px;
          margin-right: 15px;

          @media (max-width: 767px) {
            height: 44px;
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
      }

      &.practice {
        img {
          height: 44px;
          margin: 0 22px 0 9px;

          @media (max-width: 767px) {
            margin: 0 0 15px;
          }
        }
      }
    }
  }

  .options-section-3-icons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px) {
      flex-wrap: wrap;
    }

    .option {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      max-width: 215px;
      padding-right: 20px;

      &:last-child {
        padding-right: 0;
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
        max-width: 255px;
        margin: 0 auto;
        padding-bottom: 0;
        margin-bottom: 0;
        text-align: left;
        justify-content: center;

        p {
          margin-top: 10px;
          margin-bottom: 10px;

          br {
            display: none;
          }
        }
      }

      img {
        display: block;
        margin-right: 15px;
      }
    }
  }
}

.hero-golf-absolute {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 899px) {
    display: none;
  }

  @media (max-width: 767px) {
    bottom: -137px;
    width: 100%;
    padding: 20px;
  }
}

.golf-absolute-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(50%);

  @media (max-width: 767px) {
    transform: translateY(0);
  }

  p {
    margin: 0 0 12px;
    font-family: $thirdFont;
    font-weight: 500;
    font-size: 14px;
    color: white;
    line-height: 19px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .button {
    margin: 0 0 12px;

    @media (max-width: 767px) {
      display: none;
      margin: 0;
      width: 100%;
      text-align: center;
    }
  }

  a:not(.button) {
    margin: 0;
    font-family: $thirdFont;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: $dark;
    text-decoration: none;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.promo-food {
  width: 100%;
  text-align: center;
  font-size: 22px;
}

////////////////////////
// Hero Tonik Section //
///////////////////////

.hero-tonik {
  .form {
    width: fit-content;
    margin: 0 auto;
  }

  .input-wrapper-tonik {
    width: auto;
    flex-grow: 1;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;
      margin-bottom: 10px;
      padding: 0 15px;
      border: 1px solid rgba(0, 0, 0, .10);

      background-color: $dark;

      color: #fff;
      font-weight: 400;
      font-size: 1.125rem;
      font-family: $secondaryFont;

    }
  }

  .hero-footer {
    position: relative;
    width: fit-content;
    margin: 0 auto;

    .hero-more-details {
      position: relative;
      margin: 10px 0 30px;
      float: right;
    }
  }

  img.header-logo-fly-promo {
    display: block;
    margin: 25px auto;
  }

  @media (max-width: 1200px) {
    .hero-logo {
      display: none
    }

    .form {
      width: 75%;

      .input-wrapper-tonik {
        width: 50%;
      }
    }

    // .hero-more-details {
    //   width: 87.5%;
    // }
  }

  @media (max-width: 860px) {
    .form {
      width: 100%;
    }

    // .hero-more-details {
    //   width: 100%;
    // }
  }

  @media (max-width: 768px) {
    .hero-image {
      height: -webkit-fill-available;
    }

    .hero-gradient {
      height: 300px;
    }

    .hero-form-section {
      bottom: 180px;
      margin-bottom: -180px;

      .hero-footer {
        .form {
          display: flex;

          .input-wrapper-tonik {
            justify-content: start;
            width: 100%;
          }
        }
      }

      .form {
        .input-wrapper-tonik {
          div {
            justify-content: start;
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .best-price-tag {
      display: none;
    }
  }

  @media (max-width: 500px) {
    .input-wrapper-tonik {
      div {
        padding: 0px 15px 10px;

        p {
          padding-right: 50px;
        }
      }
    }

    .hero-footer {
      margin-bottom: -25px;

      .hero-more-details {
        float: left;

        bottom: 100px;
        padding-left: 15px;

        a {
          text-align: left;
        }
      }
    }

  }

}

.tooltip {
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 500px;
  background: $light;
  padding: 9px 15px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  margin-bottom: 15px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  color: $dark;
  letter-spacing: .25px;

  img {
    margin-right: 5px;
  }

  @media (max-width: 899px) {
    width: 100%;
    max-width: 400px;
    font-size: 12px;
    line-height: 1.2;
    padding-left: 55px;
    text-align: left;

    img {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0, -50%);
      width: 20px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: $light;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
}
