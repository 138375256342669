.list {
}

.section-guaranties {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: $smallScreen) {
    flex-direction: row;
  }
}

.list-guaranty-wrapper {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 25px;
  min-height: 80px;

  @media screen and (min-width: $smallScreen) {
    margin-right: 25px;
    margin-bottom: 0;
  }

  &:last-of-type {
    margin: 0;
  }
}

.list-partners {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background: $colorAccent4;

  @media screen and (min-width: $smallScreen) {
    flex-direction: row;
  }

  img {
    margin: 0 0 20px;
    width: 220px;

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }

    @media screen and (min-width: $smallScreen) {
      margin: 0 20px;
    }
  }
}
