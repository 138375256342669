// --------------------------------------------------
// General texts
// --------------------------------------------------

.title {
  margin: 0;
  color: $dark;
  font-family: $secondaryFontBold;
  @include font-size(28px);
  line-height: 32px;

  @media screen and (min-width: $smallScreen) {
    @include font-size(42px);
    line-height: 42px;
  }

  @media screen and (min-width: $bigScreen) {
    margin: 15px 0;
  }

  @media screen and (max-width: $smallScreen) {
    @include font-size(22px);
  }

  &.light {
    color: $light;
  }
}

.title2 {
  margin: 0;
  color: $dark;
  font-family: $secondaryFontBold;
  @include font-size(25px);
  line-height: 25px;


  @media screen and (min-width: $bigScreen) {
    margin: 15px 0;
    @include font-size(32px);
    line-height: 32px;
  }

  &.light {
    color: $light;
  }
}

.reading-text {
  color: $dark;
  font-family: $thirdFont;
  @include font-size(14px);
  line-height: 22px;
  font-weight: 400;

  @media screen and (min-width: $bigScreen) {
    margin: 15px 0;
    @include font-size(16px);
    line-height: 24px;
  }

  &.light {
    color: $light;
  }
}

.link {
  color: $dark;
  @include font-size(14px);
  font-weight: 400;
  text-decoration: none;
  @extend .reading-text;

  @media screen and (min-width: $bigScreen) {
    margin: 15px 0;
    @include font-size(16px);
  }

  &.light {
    color: $light;
  }
}

.rich-text-wrapper {

  ul {
    counter-reset: list;
    padding-left: 10px;
    list-style-type: none;

    @extend .reading-text;

    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: counter(list, disc);
        position: absolute;
        left: 0;
        height: 100%;
        color: $dark;
        @include font-size(15px);
        text-transform: none;
        font-weight: 600;
      }
    }
  }
}
