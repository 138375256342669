.newsletter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 24px 22px;
  background: $dark;

  @media screen and (min-width: $smallScreen) {
    padding: 48px 48px 44px;
  }

  @media screen and (min-width: $mediumScreen) {
    padding: 96px 96px 94px;
  }

  .title {
    margin: 20px 0 26px;
    @include font-size(28px);
    line-height: 32px;

    @media screen and (min-width: $smallScreen) {
      @include font-size(36px);
      line-height: 36px;
    }

    @media screen and (min-width: $bigScreen) {
      @include font-size(42px);
      line-height: 42px;
    }
  }

  .link {
    font-weight: 700;
  }

  p:last-of-type {
    margin-top: 23px;
  }
}
