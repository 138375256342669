@charset "UTF-8";
/* CSS Document */

body {
	position:absolute;
	width:100%;
}

.acBEW_inputBoxes > label {
	margin: 3px 0;
}


#acBEW_multiCity > a:link, #acBEW_multiCity > a:visited {
    color: #CC0000;
}

#acBEW_multiCity > a:active, #acBEW_multiCity > a:hover, #acBEW_multiCity > a:visited:hover {
    color: #FF0000;
}

#acBEW {
	position:relative;
	left:0;
	top:0;
	margin: 0 auto;
	overflow: hidden;
	border: solid 1px #000000;
	color: #404040;
	font-family: Arial, Helvetica, sans-serif;
	text-rendering: optimizelegibility;
    display:  inline-block;
    margin-top: 15px;
}

#acBEW_BKG {
	position:absolute;
	left:0;
	top:0;
}

#acBEW_border {
	border: 1px solid #CCCCCC;
    margin: 6px;
    padding: 6px 10px;
    position: absolute;
}

#acBEW_column1, #acBEW_column2, #acBEW_column3, #acBEW_column4 {
    margin: 0;
    padding: 0;
    position: absolute;
}

#acBEW_title {
    position: relative;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    color: #58595b;
}

#acBEW_fareType select {
    width: 124px;
}

.acBEW_selectBoxes {
	position: relative;
}

.acBEW_selectBoxes > select {
    border: 1px solid #B8B8B8;
    width: 40px;
}

.acBEW_selectLabel {
    position: absolute;
    line-height: 11px;
}

#acBEW_cta {
	margin-top: 12px;
}

.acBEW_button {
	border: medium none;
    box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.7);
    color: #FFFFFF !important;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    line-height: 100%;
    margin: 0 2px;
    outline: medium none;
    padding: 0.15em;
    text-align: center;
    text-decoration: none !important;
    vertical-align: baseline;

    background: none repeat scroll 0 0 #E80700;

    font-size: 14px;
    padding: 0.4em 1em 0.44em;
}


#acBEW_logo {
	position: absolute;
}

#acBEW_logo img {
   background: transparent;
   -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)"; /* IE8 */   
   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);   /* IE6 & 7 */ 
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    border-bottom-right-radius: 0 !important;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    border-bottom-left-radius: 0  !important;
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    border-top-right-radius: 0  !important;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    border-top-left-radius: 0  !important;
}

.ui-autocomplete {
    max-height: 140px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}
/* IE 6 doesn't support max-height
* we use height instead, but this forces the menu to always be this tall
*/
* html .ui-autocomplete {
    height: 140px;
}

 .ui-datepicker {
    width: 182px !important;
    color: #404040 !important;
}

.ui-widget {
    font-family: Arial, Helvetica, sans-serif !important;
    font-size: 0.8em !important;

}

.ui-widget-header {
    color: #404040 !important;
    font-weight: normal !important;
}

.ui-datepicker th {
    font-weight: normal !important;
}



#acBEW_toolkit {

}

#acBEW_toolkit > select {
    border: 1px solid #B8B8B8;
    font-size: 12px;
    margin: 3px;
    padding: 3px;
	width: 90px;
}



