.js-btn-form-modal {
  &.mobile {
    position: fixed;
    bottom: 15px;
    left: 20px;
    right: 20px;
    display: none;
    padding: 20px;
    z-index: 10001;

    @media (max-width: 899px) {
      display: inline-block;
    }
  }
}

// --------------------------------------------------
// Modal - Overlay
// --------------------------------------------------
.modal-new-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(#2c444d, .8);
  opacity: 0;
  z-index: -1;

  transition: all 0.25s ease-in-out;

  &.is-visible {
    opacity: .8;
    z-index: 1000000000000;
  }
}
.modal-overlay, .modal-iframe-overlay, .modal-widget-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: rgba(0, 0, 0, .8);
  opacity: 0;
  z-index: -1;

  transition: all 0.25s ease-in-out;

  &.is-visible {
    opacity: .8;
    z-index: 1000000000000;
  }
}

// --------------------------------------------------
// Modal - Form
// --------------------------------------------------
.modal-new-form {
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 100%;
  max-width: 100%;
  height: auto;
  background: $dark;
  opacity: 0;
  z-index: -1;
  transition: all 0.25s ease-in-out;
  transform: translate(-50%, 70%);

  &.is-visible {
    opacity: 1;
    z-index: 2000000000000;
    transform: translate(-50%, 0);
  }

  .form-booking {
    label {
      span {
        top: 27px;
        text-transform: uppercase;
      }
    }

    input,
    select {
      height: auto;
      margin-bottom: 0;
      border: none;
    }
  }

  .form-fields {
    display: flex;

    .input-wrapper {
      width: 50%;
      padding: 20px 0;
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  .button {
    display: block;
    width: calc(100% - 40px);
    margin: 30px 20px 20px
  }
}

.modal-form, .modal-iframe, .modal-widget {
  position: fixed;
  top: 50%;
  left: 50%;

  width: 100%;
  max-width: 435px;
  height: auto;
  padding: 25px 50px 25px;

  border-radius: 6px;
  background: #fff;
  text-align: center;

  opacity: 0;
  z-index: -1;

  transition: all 0.25s ease-in-out;
  transform: translate(-50%, -70%);

  &.is-visible {
    opacity: 1;
    z-index: 2000000000000;
    transform: translate(-50%, -50%);
  }

  .title2 {
    margin-bottom: 30px;
  }

  .button {
    margin-top: 25px;
  }

  .popup-close-btn {
    position: absolute;
    top: 12px;
    right: 20px;
    text-decoration: none;

    .close-cross {
      width: 100%;
      max-width: 15px;
    }
  }
}

.modal-iframe {
  width: 50vw;
  max-width: inherit;
  height: 450px;
  padding: 35px;

  .iframe-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    #booking-widget {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;

      @media screen and (max-width: 1594px) {
        height: 85vh;
      }
    }
  }

  @media screen and (max-width: 1594px) {
    width: 90%;
    height: 90vh;

    padding: 10px;
  }
}


// --------------------------------------------------
// Modal - Side Panel
// --------------------------------------------------

.sidepanel-details {
  display: none;
  position: relative;
}

.modal-sidepanel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);

  z-index: -1;
  transition: all 0.33s ease;

  &.is-opened {
    background: rgba(0, 0, 0, .70);
    z-index: 99;
  }

}

.modal-sidepanel-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translate(100%, 0);

  width: 100%;
  max-width: 100%;
  background: $light;

  z-index: -1;
  transition: all 0.33s ease;

  @media screen and (min-width: 655px) {
    width: 50%
  }

  @media screen and (min-width: 889px) {
    width: 45%;
  }

  @media screen and (min-width: $smallScreen) {
    width: 25%;
    min-width: 590px;
  }

  @media only screen and (max-height: 959px) {
    overflow-y: scroll;
  }

  .flickity-page-dots {
    display: none;
  }

  .flickity-prev-next-button {
    top: 20vh;
  }

  img {
    width: 100%;
    display: block;
  }

  .sidepanel-details {
    display: block;
    width: 100%;
  }

  .sidepanel-img {
    position: relative;
    width: 100%;
    height: 40vh;
    min-height: 325px;
    background-size: cover;
    background-position: center top;

    .hero-video {
      position: absolute;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 75px;
      height: 75px;
      background: url('/assets/images/play.png') no-repeat center center / contain;
      outline: none;
    }

    &.is-free {
      margin-bottom: 30px;

      .free-logo {
        position: absolute;
        left: 10px;
        bottom: -40px;
        width: 50%;

        @media screen and (min-width: $smallScreen) {
          left: 30px;
          width: 35%;
        }
      }
    }
  }

  .sidepanel-content {
    position: relative;
    padding: 20px;

    @media screen and (min-width: $smallScreen) {
      padding: 30px 40px;
    }

    .reading-text {
      margin: 20px 0 25px;

      a {
        font-weight: bold;
        @extend .reading-text;
      }
    }

    .title2 {
      margin-bottom: 0;
    }

    .sidepanel-prices {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
    }

    .price-regular,
    .price-sale {
      font-size: 28px;
      margin: 0 15px 0 0;
    }

    .price-regular {
      text-decoration: line-through;
      font-size: 22px;
    }

    .sidepanel-date {
      margin-top: 10px;
      @include font-size(14px);
    }
  }

  .sidepanel-package {
    position: relative;
    padding: 16px 20px 14px;
    background: $colorAccent2;
    margin-bottom: 20px;

    @media screen and (min-width: $smallScreen) {
      padding: 28px 20px 26px;
    }

    .title2 {
      margin: 0;
    }
  }

  .sidepanel-package-wrapper {
    display: flex;
    margin-top: 5px;
    align-items: center;

    .title2 {
      margin-right: 14px;
      color: $colorAccent3;
    }

    p.package-desc {
      margin: 0 !important;
      font-family: $secondaryFont;
      font-weight: 500;
      @extend .reading-text;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
    }

    &:after {
      position: absolute;
      top: 50%;
      right: 20px;

      display: block;
      content: '';
      width: 15px;
      height: 23px;

      background: url('../images/right-arrow-big.svg') no-repeat center;
      transform: translateY(-50%);
    }
  }

  &.is-opened {
    transform: translate(0, 0);
    z-index: 100;
    padding-bottom: 40px;
    overflow-y: scroll;
  }

  .popup-close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    text-decoration: none;
    z-index: 2;

    .close-cross {
      width: 100%;
      max-width: 15px;
    }
  }
}
