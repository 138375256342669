// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: auto;
  font-size: 16px;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $primaryFont;
  font-weight: 300;
  font-size: 16px;
  display: flex;
  height: 100%;
  flex-direction: column;
}

img {
  vertical-align: middle;
}

.main {
  flex: 1;
  background: $grey;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex-basis: auto;
  }
}


// Helpers
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.grey {
  background: $grey;
}

.container {
  padding: 0 25px;

  @media screen and (min-width: $smallScreen) {
    padding: 0 40px 0;
  }

  @media screen and (min-width: $bigScreen) {
    padding: 0 80px;
  }
}
