// --------------------------------------------------
// Variables
// --------------------------------------------------
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// --------------------------------------------------
// Fonts
// --------------------------------------------------

$primaryFont: 'helvetica_std', Helvetica, Arial, sans-serif;
$primaryFontBold: 'helvetic_med', Helvetica, Arial, sans-serif;
$secondaryFont: 'stag', Helvetica, Arial, sans-serif;
$secondaryFontBold: 'stag_bold', Helvetica, Arial, sans-serif;
$thirdFont: "Open Sans", Helvetica, Arial, sans-serif;

// --------------------------------------------------
// Colors
// --------------------------------------------------

$light: #fff;
$dark: #2C444D;
$grey: #f6f6f6;
$regularGrey: #d4d4d4;
$lightGrey: #f2f6f7;
$colorAccent1: #AB192D;
$colorAccent2: #E6EFF8;
$colorAccent3: #AD1529;
$colorAccent4: #e8edf1;


// --------------------------------------------------
// Breakpoints
// --------------------------------------------------

$tinyScreen: 480px;
$smallScreen: 768px;
$mediumScreen: 1024px;
$largeScreen: 1280px;
$bigScreen: 1440px;
