// --------------------------------------------------
// Footer
// --------------------------------------------------

.footer {
  display: flex;
  flex-direction: column;
  padding: 5px 20px;
  background: $light;
  text-align: center;

  @media screen and (min-width: $smallScreen) {
    text-align: center;
  }

  @media screen and (max-width: $smallScreen) {
    margin-top: 20px;
  }

  a {
    margin: 0;
    font-size: 14px;

    @media screen and (max-width: $smallScreen - 1px) {
      display: block;

      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }

  .separator {
    display: none;

    @media screen and (min-width: $smallScreen) {
      display: inline-block;
      margin: 15px 0;
    }
  }

  .link {
    font-weight: 400;
    margin: 0 10px;

    &:last-of-type {
      margin-right: 0;
    }

    @media screen and (max-width: $smallScreen - 1px) {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .left-footer {
     @media screen and (max-width: $smallScreen - 1px) {
       margin-top:0;
     }
  }

  .right-footer {
    margin: 0;

    p {
      font-size: 14px;
      display: inline-block;
    }
  }
}

.exchange-text {
  text-align: center;

  .reading-text {
    font-size: 14px;
  }
}
