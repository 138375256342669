.section-warranties {
  margin: 40px auto 60px;
  text-align: center; width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: stretch;
  justify-content: center;

  @media (max-width: 899px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 15px;
    padding: 20px 20px 60px;
    background: $light;
  }

  .warranty {
    display: flex;
    align-items: center;
    width: calc(100% / 3);
    background: $light;
    padding: 15px 30px;
    margin-right: 10px;

    &.half {
      width: calc(100% / 2);

      @media (max-width: 899px) {
        width: 100%;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media (max-width: 899px) {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      padding: 0 0 15px;

      &:last-of-type {
        padding: 0 0 0;
      }
    }

    .warranty-content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
    }

    .warranty-img {
      height: 70px;
      margin-right: 20px;
    }

    .warranty-text {
      font-family: $secondaryFontBold;
      color: $dark;
      text-align: left;
      font-size: 20px;
      line-height: 24px;
      margin: 0;

      @media (max-width: 899px) {
        font-size: 21px;
        line-height: 1;
        width: 100%;
      }
    }

    .warranty-subtext {
      font-family: $thirdFont;
      color: $dark;
      text-align: left;
      font-size: 14px;
      line-height: 1.2;
      font-weight: 500;
      margin: 0;

      @media (max-width: 899px) {

      }
    }
  }
}

/////////////////////
// TONIK ARGUMENTS //
////////////////////

.section-tonik-args {
  padding: 35px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 20px;
  }

  .tonik-arg {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;

    border-right: 1px solid $grey;

    &:last-child {
      border-right: none;
    }

    @media (max-width: 767px) {
      flex-direction: row;
      align-items: normal;
      max-width: 100%;

      border-right: none;

      &:nth-child(1), &:nth-child(2) {
        margin-bottom: 20px;
      }
    }

    .tonik-arg-img {
      height: 50px;

      @media (max-width: 767px) {
        height: 40px;
        margin-right: 20px;
      }
    }

    .tonik-arg-text {
      font-family: $secondaryFont;
      color: $dark;
      text-align: center;
      font-size: 18px;
      line-height: 18px;
      margin: 15px 0 0 0;
      width: 80%;

      @media (max-width: 767px) {
        text-align: left;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
      }
    }
  }
}
