.crm-alerte
{
  position: fixed;
  right: 15px;
  bottom: 15px;

  max-width: 380px;
  padding: 35px 45px;

  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 rgba(50,50,50,0.2);
  opacity: 1;

  text-align: center;

  z-index: 10000;

  &.crm-out
  {
    right: -110%;
    opacity: 0;
  }

  transition: all 0.5s ease-in-out;

  img
  {
    display: inline-block;
    width: 60px;
    margin-bottom: 10px;
  }

  p
  {
    //font-family: $opensans;
    line-height: 1.2;
    font-size: 15px;
    //color: $secondaryColor;

    &.small
    {
      font-size: 12px;
    }
    &.close
    {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 22px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  a
  {
    //background-color: $secondaryColor;
    color: #fff;
    //font-family: $opensans;
    font-size: 15px;
    padding: 10px 27px;
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 700px)
{
  .crm-alerte
  {
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: none;
    padding: 30px 15px;
  }
}
