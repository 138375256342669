.button {
  position: relative;
  display: inline-block;
  padding: 16px 55px 12px 29px;
  margin: 0;
  background: $colorAccent1;

  color: $light;
  font-family: $secondaryFont;
  font-weight: 500;
  @include font-size(16px);
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;

  transform: scale(1,1);
  transition: all 0.2s cubic-bezier(0, 0.000, 0.175, 1.000);

  @media screen and (min-width: $bigScreen) {
    padding: 20px 74px 16px 29px;
    @include font-size(18px);
  }

  &.has-arrow:after {
    position: absolute;
    top: 50%;
    right: 22px;

    display: block;
    margin-top: auto;
    margin-bottom: auto;
    content: '';
    width: 7px;
    height: 12px;

    background-image: url(/assets/images/right-arrow-picto.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    transform: translateY(-50%);
  }

  &.light {
    border: 2px solid $dark;
    background: $light;
    color: $dark;

    &:after {
      color: $dark;
      background-image: url(/assets/images/right-arrow-picto-grey.svg);
    }
  }

  &.outline {
    margin-top: 30px;
    background: transparent;
    border: 2px solid $light;
    color: $light;

    &:after {
      color: $light;
      background-image: url(../images/right-arrow-picto-white.svg);
    }
  }

  &:hover
  {
    transform: scale(0.95,0.95);
  }
}


.smt-sticky {
  display: none;

  @media (max-width: 899px) {
    position: fixed;
    bottom: 15px;
    left: 20px;
    right: 20px;
    display: block;
    z-index: 999;
  }
}
