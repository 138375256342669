.login {
  position: fixed;
  top: 0;
  left: -1000vh;
  z-index: -300;
  width: 500px;
  max-width: calc(100% - 20px);
  height: auto;
  max-height: calc(100vh - 20px);
  margin: 10px;
  background-color: #fff;
  transform: translate(-50%, -50%) scale(1.4);
  overflow: auto;
  opacity: 0;
  transition: opacity .3s ease-out,transform .3s ease-out;

  &.is-active {
    top: 50%;
    left: calc(50% - 10px);
    z-index: 9999;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }

  .button {
    border: none;
    padding-right: 29px;
  }
}

.login-nav {

  .title4 {
    width: calc(100% / 2);
    padding: 20px 10px;
    background-color: $light;
    color: $dark;

    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #fff;
    }

    &.is-active {
      color: #fff;
      background-color: $colorAccent1;
    }
  }
}

.login-content {
  padding: 30px;

  @media (max-width: 767px) {
    padding: 15px;
  }

  .title3 {
    font-size: 28px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
  }
}

.login-type {
  display: none;
  text-align: center;

  &.is-active {
    display: block;
  }

  .main-text {
    margin-bottom: 10px;
    text-align: left;

    &.center {
      text-align: center;
      margin-bottom: 25px;
    }
  }

  .title4 {
    font-weight: 400;
    margin-bottom: 5px;
    font-size: 14px;
    letter-spacing: 0;
    text-align: left;
  }

  .type-text {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    height: 45px;
    font-weight: 500;
    color: $dark;
    font-size: 14px;
    letter-spacing: 0;
    text-align: left;
    border: 1px solid #E6E6E6;

    &:focus {
      outline: 2px solid $colorAccent1;
    }
  }

  .type-checkbox {
    display: block;
    font-weight: 500;
    color: $dark;
    letter-spacing: 0;
    margin-bottom: 15px;
    font-size: 14px;
    text-align: left;
  }

  .main-text {
    font-weight: 500;
    color: $dark;
    letter-spacing: 0;
    font-size: 14px;
  }

  button {
    width: 100%;
    height: 40px;
    margin: 10px auto 0;
    padding: 10px;
    border: 0;
    border-radius: 3px;

    background-color: $dark;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;

    outline: none;
    box-shadow: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;


    &:hover {
      background-color: $colorAccent1;
    }
  }

  .password-forgot {
    display: block;
    margin: -10px 0 20px;
    font-size: 11px;
    color: $dark;
    text-transform: uppercase;
    text-align: right;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: #000;
    }
  }

  .create-account {
    display: block;
    margin-top: 20px;
    font-size: 11px;
    color: $dark;
    text-align: center;
    font-weight: 400;
    text-decoration: none;

    a {
      padding-bottom: 2px;
      text-decoration: none;
      color: $colorAccent1;
      border-bottom: 1px solid transparent;

      transition: all 0.3s ease-in-out;

      &:hover {
        border-color: $colorAccent2;
      }
    }
  }
}

.login-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: $dark;
  opacity: 0.99;
  display: none;

  .login-close {
    position: fixed;
    right: 25px;
    top: 25px;
    color: #fff;
    font-size: 40px;
    cursor: pointer;
  }
}
