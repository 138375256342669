@charset "UTF-8";
/* CSS Document */

.acBEW_inputBoxes > input {
	width: 120px;
	margin: 2px 0 3px;
}

#acBEW {
	height:250px;
	width: 300px;

	font-size:10px;

    background: url("/img/site/acBEW_300x250_BKG.jpg") left top no-repeat;
}

#acBEW_border {
    height: 237px;
    width: 287px;
}

#acBEW_column1 {
	top: 34px;
	left: 10px;
    width: 130px;
}

#acBEW_column2 {
	top: 34px;
	left: 150px;
    width: 130px;
}

#acBEW_rondelle {
    left: 13px;
    top: 7px;
    position: absolute;
}

#acBEW_title {
	font-size: 13px;
    line-height: 21px;
    left: 34px;
}

#acBEW_fareType {
	margin-top: 8px;
}

#acBEW_fareType select {
    width: 124px;
}

.acBEW_selectBoxes > select {
    font-size: 12px;
    margin: 2px 0;
    padding: 2px;
}

.acBEW_selectLabel {
    left: 46px;
    top: 3px;
}

#acBEW_multiCity {
    margin: 3px 0 0 2px;
}

#acBEW_cta {
	margin-top: 12px;
}

#acBEW_logo {
	left: 134px;
    top: 216px;
}
