.terms-wrap {
  width: 100%;
  padding: 40px 15px 40px 15px;

  background-color: $lightGrey;

  .container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;

    @media (max-width: 899px) {
      padding-top: 50px;
    }

    .block-heading,
    .block-text {
      margin: 0;
      text-align: left;
    }
  }

  .terms-header {
    width: 100%;
    padding: 25px;
    margin-bottom: 25px;

    background-color: #fff;

    h1 {
      margin: 0;
      font-size: 28px;
      @extend .title;
    }
  }

  h2,b,h3,strong {
    font-size: 18px;
    font-weight: 600;
    @extend .reading-text;
  }

  ul {
    width: 100%;
    list-style-position: outside;
    padding-left: 20px;

    li {
      font-size: 14px;
      @extend .reading-text;
      margin: 5px 0;

      a {
        @extend .link;
      }
    }
  }

  p {
    @extend .reading-text;
  }

  .cta-medium-secondary {
    display: inline-block;
    padding: 10px 20px 8px 30px;
    text-decoration: none;
    font-size: 18px;
    font-family: $secondaryFont;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 25px;

    background-color: $dark;
    background-image: url('/assets/images/arrow-left.png');
    background-position: 12px center;
    background-repeat: no-repeat;
    background-size: 8px;
  }
}
