.promo-header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  position: relative;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }

  &.for-us {
    flex-direction: column;

    .overlay {
      top: unset;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 60px;
    }
  }

  &.for-toronto {
    .tool-bar-wrap {
      height: auto;
    }

    @media screen and (max-width: 1024px) {
      .overlay {
        display: none;
      }
    }
  }

  &.double-header {
    .countdown-wrap {
      padding-bottom: 0;
    }

    .regional-toolbar {
      .tool-bar {
        margin-top:0;

        @media screen and (max-width: 600px) {
          margin-top: 10px;
        }

        .plane-wrap {
          padding-left: 0;
        }
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;

  width: 50%;
  height: 100%;

  background: rgba(#000, .1);
}

.flash-geolocalisation
{
  .tool-bar-wrap
  {
    background-color: #1c383f;
  }
}

.tool-bar-wrap
{
  display: flex;
  width: 100%;
  text-align: center;
  background-color: $colorAccent1;
  padding: 5px 0;
  height: 60px;

  @media screen and (max-width: 900px) {
    .usa-wrap {
      padding-right: 20px;

      &.currency {
        p:first-child {
          display: none;
        }
      }
    }
  }

  .tool-bar
  {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;

    @media screen and (max-width: $smallScreen) {
      display: block;
      margin: 20px auto;
    }

    .plane-wrap,.usa-wrap
    {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex: 1 1 100%;
      height: 100%;
    }

    .usa-wrap {

      &.currency {
        p {
          font-family: $thirdFont;
          font-size: 16px;
          font-weight: 600;
        }
      }

      &.fly {
        p {
          font-family: $thirdFont;
          font-size: 16px;
          font-weight: 600;
          text-align: left;
        }
      }
    }

    p
    {
      position: relative;
      margin: 0;
      display: inline-block;

      color: #fff;
      font-weight: 600;
      font-size: 14px;
      @extend .reading-text;
    }

    .plane
    {
      position: relative;
      height: 22px;
      display: inline-block;
      float: left;
      margin: 0 20px;
    }

    .usa
    {
      position: relative;
      height: 22px;
      display: inline-block;
      float: left;

      margin: 0 20px;
    }
  }

  @media screen and (max-width: 460px) {
    padding: 0;

    .tool-bar {
      margin: 15px 0 10px;

      .plane-wrap {
        padding-left: 0;

        .usa-wrap {
          padding-left: 0;

          .usa {
            margin: 0 10px;
          }

          p {
            font-size: 12px;
            line-height: 1;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 550px)
{
  .tool-bar-wrap
  {
    .plane-wrap,.usa-wrap
    {
      padding-left: 15px;
    }
    .plane-wrap
    {
      margin-bottom: 5px;
    }
    .tool-bar
    {
      width: 100%;
      .usa
      {
        margin-left: 0;
        width: 35px;
      }
      .toronto
      {
        margin-left: 10px;
        line-height: 1.2;
        text-align: left;
      }
    }

  }
}

.countdown-wrap
{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  width: 100%;
  padding: 10px;

  background-color: $colorAccent1;
  text-align: center;

  &.dark-theme
  {
    background-color: #1c383f;
    margin-top: 3px;
  }

  @media only screen and (max-width: 600px)
  {
    display: flex;
    flex-direction: column;
    z-index: 1;
    // height: auto;
  }

  h1
  {
    display: inline-block;
    font-family: $secondaryFontBold;
    color: #fff;
    font-size: 22px;
    position: relative;
    margin-top: 0;
    margin-right: 15px;

    @media (max-width: 600px)
    {
      display: block;
      margin-right: 0;
      margin-bottom: 6px;
      font-size: 18px;
    }

    @media (max-width: 500px)
    {
      // display: none;
    }
  }

  .clock-wrap
  {
    position: relative;
    display: flex;
    flex-direction: column;


    @media only screen and (max-width: 600px)
    {
      height: 60px;
    }

    .clock {
      height: 45px;
    }

    .label
    {
      color: #fff;
      font-family: $secondaryFontBold;
      font-size: 13px;

      @media only screen and (max-width: 600px)
      {
        bottom: 11px;
      }

      &.day
      {
        left: 22px;
      }
      &.hour
      {
        left: 78px;
      }
      &.minute
      {
        left: 132px;
      }
      &.seconde
      {
        left: 192px;
      }
    }

    .clock-dates {
      display: flex;
      justify-content: space-around;

      p{
        margin: 0;
      }
    }
  }
}

.flip-clock-wrapper ul li a div div.inn
{
  color: $dark;
}
