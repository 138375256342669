.page404 {

  .bg-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 100px);
    background-size: cover;

    p,
    h1 {
      text-shadow: -1px 1px 3px rgba(0,1,1,.6);
    }
  }
}
