.form {
  width: 100%;

  /**
  * GENERAL
  **/
  input {
    position: relative;
    border: none;
    background: $light;
    padding: 16px 16px 14px;
    outline: 0;
  }

  input[type="submit"],
  button {

    outline: none;
    border: none;

  }

  select {
    -webkit-appearance: none;

    background-image: url(/assets/images/arrow-down.png);
    background-repeat: no-repeat;
    background-position: right 20px center;
  }

  .input-wrapper {
    position: relative;
  }

  .has-error {
    input,
    select {
      background: #e74c3c;
    }
  }

  /**
  * PARSLEY
  **/
  .parsley-errors-list {
    position: absolute;
    right: 0;
    bottom: -7px;
    font-size: 12px;
    font-weight: 500;
    color: #DA620C;
  }

  input.parsley-success,
  select.parsley-success,
  textarea.parsley-success {
    color: #3a3a3a;
  }

  input.parsley-error,
  select.parsley-error,
  textarea.parsley-error {
    color: #DA620C;
  }

  /**
  * DATEPICKER
  **/
  .js-datepicker {
    background-image: url(/img/site/calendar.svg);
    background-repeat: no-repeat;
    background-position: right 18px center;
  }
}

.form-newsletter {

  .input-wrapper {
    margin: auto;

    @media screen and (min-width: $smallScreen) {
      max-width: 375px;
    }
  }

  input {
    width: 100%;
    margin: 0;
  }

  input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    background: $colorAccent1;
    border-radius: 0;

    &:after {
      position: absolute;
      top: 50%;
      right: 20px;

      display: block;
      content: '';
      width: 15px;
      height: 9px;

      background: url('/assets/images/right-arrow-picto.svg') no-repeat center;
      transform: translateY(-50%);
    }
  }

  .single-input:after {
    position: absolute;
    top: 50%;
    right: 20px;

    display: block;
    content: '';
    width: 15px;
    height: 20px;

    background: url('/assets/images/right-arrow-picto.svg') no-repeat center;
    transform: translateY(-50%);

  }
}

.form-booking {
  padding: 0 0 0 0;

  label {
    display: inline-block;
    width: 100%;
    text-align: left;
    color: #fff;
    font-weight: 400;
    font-size: 15px;

    span {
      display: block;
      color: #fff;
      position: absolute;
      padding: 0 20px;
      top: 19px;
      line-height: 1;
      font-family: $thirdFont;
      font-weight: 600;

      z-index: 1;
      transition: all 0.2s cubic-bezier(0, 0.000, 0.175, 1.000);
    }

    .first-option {
      font-size: 15px;
    }

    &.is-active {
      span {
        transform: scale(.68);
        top: 5px !important;
        padding-left: 0;
      }

      select {
        font-weight: normal;
        font-size: inherit;
        padding-top: 12px;
        padding-left: 14px;
      }
    }

    &[for="f-arrival"] {
      .picker {
        @media (max-width: 767px) {
          top: 10px;
          width: 99vw;
        }
      }
    }

    &[for="f-departure"] {
      .picker {
        @media (max-width: 767px) {
          top: 10px;
          left: -100%;
          width: 99vw;
        }
      }
    }
  }

  input,
  select
  {
    width: 100%;
    height: 50px;
    padding: 5px 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, .10);

    background-color: $dark;
    border-radius: 3px;

    color: #fff;
    font-weight: 600;
    font-size: 16px;
    font-family: $thirdFont;
  }

  select {
    padding-left: 20px;
    font-weight: bold;
  }

  .select-wrapper span{
    padding-left: 36px;
  }
}
