// --------------------------------------------------
// App
// --------------------------------------------------

// Fonts
@font-face {
  font-family: 'helvetica_std';
  src: url('/assets/fonts/helveticaneueltpro-hv.woff') format('woff'),
  url('/assets/fonts/helveticaneueltpro-hv.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica_med';
  src: url('/assets/fonts/helveticaneueltpro-md.woff') format('woff'),
  url('/assets/fonts/helveticaneueltpro-md.tff') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'stag';
  src: url('/assets/fonts/stag-medium.woff') format('woff'),
  url('/assets/fonts/stag-medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'stag_bold';
  src: url('/assets/fonts/stag-bold.woff') format('woff'),
  url('/assets/fonts/stag-bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

// Vendors
@import 'vendors/normalize';
@import 'vendors/flickity';
@import 'vendors/pickadate';
@import 'vendors/lity';
@import 'vendors/acBEW';
@import 'vendors/acBEW_300x250';
@import 'vendors/jquery-ui.min';
@import 'vendors/jquery-ui.structure.min';
@import 'vendors/jquery-ui.theme.min';
@import 'vendors/flipclock';
// @import 'vendors/aos';

// Common
@import 'common/variables';
@import 'common/typography';
@import 'common/form';
@import 'common/base';

// Layouts
@import 'layouts/header';
@import 'layouts/content';
@import 'layouts/footer';

// Partials
@import 'partials/hero';
@import 'partials/blocks';
@import 'partials/button';
@import 'partials/lists';
@import 'partials/modal';
@import 'partials/newsletter';
@import 'partials/toolbar';
@import 'partials/crm-alert';
@import 'partials/login';
@import 'partials/warranties';

// Pages
@import 'pages/404';
@import 'pages/campaign';
@import 'pages/terms';
