// --------------------------------------------------
// Content
// --------------------------------------------------

.content {
  padding: 25px 0;

  @media screen and (min-width: $smallScreen) {
    padding: 30px 0 40px;
  }

  @media screen and (min-width: $bigScreen) {
    padding: 0;
  }

  @media screen and (max-width: 899px) {
    padding: 25px 0 0;
  }

  &.smt {
    margin-top: 125px;

    @media (max-width: 1439px) {
      margin-top: -30px;
    }

    @media (max-width: 899px) {
      margin-top: -90px;
    }
  }
}
